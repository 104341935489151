import React, { useMemo } from 'react';

import ColoredCircle from 'common-ui-components/ColoredCircle/index';
import { GroupedPersons } from 'global/api/controller/PersonController';
import Size from 'global/lists/Size';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import RootCollapsibleGroup
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/RootCollapsibleGroup';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import usePeopleHierarchicalSearchResultsReducer
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchResults/PeopleHierarchicalSearchResultsReducer';

import style
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/ColumnsHierarchicalSearchInputWrapper/style.module.scss';

interface Props {
  selection: PeopleMasterFilterHierarchyGroup;
  visibleItems: GroupedPersons;
  handleEmptySelectionChange?: (isEmpty: boolean) => void;
  showDepartmentsOnly?: boolean;
}

const {
  UNDEFINED_PROPERTY,
  UNDEFINED_PROPERTY_LABEL,
} = NullableValuesSelectionConstants;

export default function PeopleHierarchicalSearchResults({
  selection,
  visibleItems,
  handleEmptySelectionChange,
  showDepartmentsOnly = false,
}: Props) {
  const { getDepartmentColor } = useTenantContext();
  const { state, getDispatchFunction } = usePeopleHierarchicalSearchResultsReducer(selection);

  const isTeamsSectionVisible = useMemo<boolean>(() => {
    const group = selection.getTeamsGroup();
    return FilterSectionUtils.checkFilterHasDefinedValues(group);
  }, [selection]);

  const teamsFilter = isTeamsSectionVisible ? (
    <RootCollapsibleGroup
      label="Teams"
      group={state.teamsSelection}
      visibleItemsIds={visibleItems.teams}
      onToggle={getDispatchFunction('TOGGLE_TEAM')}
      initiallyOpen
      getItemSizeLabel={(itemId) => `(${state.teamsToPeople[itemId]?.length || 0} People)`}
      getItemLabel={(itemId) => (itemId === UNDEFINED_PROPERTY ? UNDEFINED_PROPERTY_LABEL : itemId)}
    />
  ) : null;

  const departmentsFilter = (
    <RootCollapsibleGroup
      label="Departments"
      group={state.departmentsSelection}
      visibleItemsIds={visibleItems.departments}
      onToggle={getDispatchFunction('TOGGLE_DEPARTMENT')}
      initiallyOpen
      getItemSizeLabel={(department) => `(${state.departmentsToPeople[department]?.length || 0} People)`}
      getItemImage={(department) => (
        <ColoredCircle color={getDepartmentColor(department)} gradient size={Size.SMALL} />
      )}
    />
  );

  const peopleFilter = (
    <RootCollapsibleGroup
      label="People"
      group={state.peopleSelection}
      visibleItemsIds={visibleItems.allPeople}
      initiallyOpen
      handleEmptySelectionChange={handleEmptySelectionChange}
      onToggle={getDispatchFunction('TOGGLE_PERSON')}
      type={HierarchicalItemType.PERSON}
    />
  );

  if (showDepartmentsOnly) {
    return departmentsFilter;
  }

  return (
    <div className={style.filterColumns}>
      <section className={style.column}>
        {teamsFilter}
        {departmentsFilter}
      </section>
      <section className={style.column}>
        {peopleFilter}
      </section>
    </div>
  );
}
