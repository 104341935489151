import { AxiosRequestConfig } from 'axios';

import axios from 'global/api/axiosInstance';
import HttpStatus from 'global/lists/HttpStatus';

type GetEndpoint =
  | 'atom/highlights'
  | 'atom/recent'
  | 'backoffice/akooda-employees'
  | 'backoffice/email-app/ignored-senders'
  | 'backoffice/session'
  | `backoffice/session/${string}`
  | `backoffice/session/person/${string}`
  | 'backoffice/tags-set/suggested/categories'
  | 'channel/query'
  | 'config'
  | `configuration/${string}`
  | 'connector/slack-workspace-subdomain'
  | 'enterpriseSearch/suggestedQuestions'
  | 'feed/events'
  | 'feed/objectives'
  | 'image'
  | 'image/logo'
  | 'image/organization'
  | 'image/domain'
  | 'master-filters'
  | 'metric'
  | 'subscription'
  | 'objective'
  | `objective/${string}`
  | 'organization'
  | 'organization/filterOptions'
  | 'organization/settings'
  | 'organization/query'
  | 'person'
  | 'person/contact'
  | 'person/export'
  | 'person/follow'
  | `person/${string}`
  | 'person/settings'
  | 'person/team'
  | 'report'
  | `settings/person/${string}/extension`
  | 'setup/active-connectors'
  | 'setup/connector-config'
  | 'setup/gmail-connected-email'
  | 'setup/orgchart'
  | 'setup/unmapped-users'
  | 'tag'
  | 'tags-set'
  | 'tags-set/categories-colors'
  | 'tags-set/suggested'
  | `tag/directory/${string}`
  | 'tenant'
  | 'tenant/app'
  | 'tenant/findAll'
  | 'tenant/integration/slack-bot'
  | 'timeline/organization/directory'

type PostEndpoint =
  | 'announcement/feed'
  | 'announcement/markRead'
  | 'atom'
  | 'atom/count'
  | 'atom/summary'
  | 'attention'
  | 'attention/build'
  | 'backoffice/manual-emails-generation'
  | 'backoffice/tags-set/suggested'
  | 'backoffice/tags-set/suggested/convert'
  | 'brief'
  | 'channel'
  | 'customLocation'
  | 'customLocation/save'
  | 'enterpriseSearch'
  | 'enterpriseSearch/followupQuestions'
  | 'entity/summary'
  | 'event'
  | 'eventDetection'
  | 'event/summary'
  | 'export/directory/organization-new'
  | 'export/directory/person'
  | 'feedback'
  | 'free-text-question'
  | 'graphData'
  | 'metric'
  | 'metricDetection'
  | 'metric/toggle'
  | 'metric/organizations'
  | 'notification'
  | 'objective'
  | 'objectiveTracker'
  | 'ods'
  | 'organization'
  | 'organization/active-new'
  | 'organization/metrics'
  | `organization/${string}`
  | 'person'
  | 'person/areasOfFocus'
  | 'person/external'
  | 'person/follow'
  | 'person/internal'
  | 'person/organizations'
  | 'person/orgchart'
  | 'person/query'
  | 'person/top-people'
  | 'search'
  | 'setup/logo'
  | 'setup/orgchart'
  | 'setup/phabricator'
  | 'subscription'
  | 'tags-set'
  | 'tags-set/category'
  | 'tags-set/tag'
  | 'tag/directory'
  | 'timeline/graphData'

type PutEndpoint =
  | 'backoffice/email-app/ignored-senders'
  | 'config'
  | 'master-filters'
  | 'subscription'
  | `objective/${string}`
  | 'person'
  | `settings/person/${string}/extension`
  | 'tags-set/category'
  | 'tags-set/tag'
  | 'tenant'
  | 'tenant/department/color'
  | 'tenant/setupState'
  | 'tenant/integration/slack-bot'

type DeleteEndpoint =
  | 'backoffice/email-app'
  | 'config'
  | 'master-filters'
  | 'subscription'
  | `objective/${string}`
  | `person/unfollow/${string}`
  | 'tags-set'
  | 'tags-set/category'
  | 'tags-set/tag'

export type HttpResponseResult<T> = { data: T; status?: HttpStatus } | null;
export type HttpResponse<T> = Promise<HttpResponseResult<T>>;

async function requestWithBody<T, S>(
  funcName: 'post' | 'put',
  endpoint: S,
  body: object | null = {},
  requestConfig = {},
): HttpResponse<T> {
  return axios[funcName](`/${endpoint}`, body, requestConfig);
}

async function requestWithoutBody<T, S>(
  funcName: 'get' | 'delete',
  endpoint: S,
  params: AxiosRequestConfig['params'] = {},
  requestConfig: AxiosRequestConfig = {},
): HttpResponse<T> {
  return axios[funcName](`/${endpoint}`, params, requestConfig);
}

export async function getRequest<T>(
  endpoint: GetEndpoint,
  params: AxiosRequestConfig['params'] = {},
  requestConfig: AxiosRequestConfig = {},
): HttpResponse<T> {
  return requestWithoutBody<T, GetEndpoint>('get', endpoint, params, requestConfig);
}

export async function deleteRequest<T>(
  endpoint: DeleteEndpoint,
  params = {},
  body?: object,
): HttpResponse<T> {
  return requestWithoutBody<T, DeleteEndpoint>('delete', endpoint, params, { data: body });
}

export async function postRequest<T>(
  endpoint: PostEndpoint,
  body: object | null,
  requestConfig = {},
): HttpResponse<T> {
  return requestWithBody<T, PostEndpoint>('post', endpoint, body, requestConfig);
}

export async function putRequest<T>(
  endpoint: PutEndpoint,
  body: object | null = {},
  requestConfig = {},
): HttpResponse<T> {
  return requestWithBody<T, PutEndpoint>('put', endpoint, body, requestConfig);
}

export function isSuccessful(response: HttpResponseResult<any>): boolean {
  if (response?.status) {
    return response.status >= 200 && response.status < 300;
  }
  return false;
}
