import classNames from 'classnames';
import React from 'react';

import Sources from 'common-ui-components/Sources';
import { Paragraph } from 'common-ui-components/Typograhpy';
import FollowupQuestions from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/FollowupQuestions';
import RelevantPeopleSection from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/RelevantPeopleSection';
import { EnrichedEnterpriseSearchResponse } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import Disclaimer from 'screens/platform/cross-platform-components/Disclaimer';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/AnswerSessionContent/style.module.scss';

interface Props {
  data: EnrichedEnterpriseSearchResponse;
  integrateRelevantPeopleInAnswer: boolean;
}

export default function AnswerSessionContent({
  data,
  integrateRelevantPeopleInAnswer,
}: Props) {
  return (
    <>
      <div className={classNames(style.answer, 'answer')}>
        <Paragraph>
          {data.answer.split('\n').map((line) => (
            <div key={crypto.randomUUID()}>
              {line}
            </div>
          ))}
          {integrateRelevantPeopleInAnswer && (
            <RelevantPeopleSection
              relevantPeople={data.relevantPeople}
              includeTitle={false}
            />
          )}
        </Paragraph>
        <Disclaimer sessionId={data.sessionId} isSuccessfulAnswer={data.isSuccessfulAnswer} location="Enterprise Search" />
      </div>
      <Sources sources={data.sources} />
      {!integrateRelevantPeopleInAnswer && (
        <RelevantPeopleSection relevantPeople={data.relevantPeople} />
      )}
      {data.isSuccessfulAnswer && (
        <FollowupQuestions searchResponse={data} />
      )}
    </>
  );
}
