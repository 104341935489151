import React, { useCallback } from 'react';

import Button from 'common-ui-components/Button';
import { useCurrentAppScreen } from 'global/lists/AppScreen';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import getInitialDatesRange from 'screens/platform/directory/components/ProfileTabs/components/Filters/DateRangeUtils';
import { FiltersProps } from 'screens/platform/directory/components/ProfileTabs/components/Filters/index';
import ObjectUtils from 'utils/ObjectUtils';

import style from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton/style.module.scss';

export default function ClearFiltersButton({
  requiredEntity,
  entityValue,
  disabled,
}: FiltersProps) {
  const { dispatchCustomAtomsFilters, customAtomsFilters } = useCustomAtomsFilters();
  const { isTopicProfileScreen } = useCurrentAppScreen();

  function getDefaultFilters() {
    let requiredEntityFilters = {};

    if (requiredEntity && entityValue) {
      requiredEntityFilters = {
        [requiredEntity]: [entityValue],
        requiredEntity: {
          type: requiredEntity,
          values: [entityValue],
        },
      };
    }

    return {
      datesRange: getInitialDatesRange(),
      directionality: [Directionality.EXTERNAL, Directionality.INTERNAL],
      ...requiredEntityFilters,
      includeUntagged: !isTopicProfileScreen,
    };
  }

  const defaultFilters = getDefaultFilters();

  const handleResetFilters = useCallback(() => {
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: defaultFilters,
    });
  }, [requiredEntity, entityValue]);

  if (ObjectUtils.equals(customAtomsFilters, defaultFilters)) return null;

  return (
    <Button
      onClick={handleResetFilters}
      transparent
      className={style.clearFilterButton}
      disabled={disabled}
    >
      Clear
    </Button>
  );
}
