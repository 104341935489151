import React from 'react';

import SuggestedQuestion, {
  SuggestedQuestionSkeleton,
} from 'es-src/screens/HomeScreen/components/SuggestedQuestions/SuggestedQuestion';
import useSuggestedQuestions from 'es-src/screens/HomeScreen/components/SuggestedQuestions/SuggestedQuestionsHook';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

import style from 'es-src/screens/HomeScreen/components/SuggestedQuestions/style.module.scss';

export default function SuggestedQuestions() {
  const {
    loading: esLoading,
    isResultCardOpen,
    hasError,
  } = useEnterpriseSearchContext();
  const { suggestedQuestions, loading } = useSuggestedQuestions();
  const shouldNotRenderSuggestedQuestions = isResultCardOpen || esLoading || hasError;

  if (shouldNotRenderSuggestedQuestions) return null;

  if (loading) return <SuggestedQuestionsSkeleton />;

  if (!suggestedQuestions || suggestedQuestions.length === 0) return null;

  return (
    <div className={style.suggestedQuestionsContainer}>
      {suggestedQuestions.map((question) => (
        <SuggestedQuestion key={question} question={question} questionSource="suggestedQuestion" color="white" />
      ))}
    </div>
  );
}

function SuggestedQuestionsSkeleton() {
  return (
    <div className={style.suggestedQuestionsContainer} role="progressbar">
      <SuggestedQuestionSkeleton color="white" />
    </div>
  );
}
