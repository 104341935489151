import React from 'react';

import style
  from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount/style.module.scss';

interface Props {
  totalAtomsCount: number;
  filteredAtomsCount: number;
}

export default function InteractionsCount({
  filteredAtomsCount,
  totalAtomsCount,
}: Props) {
  if (totalAtomsCount === 0) return null;

  const title = `${filteredAtomsCount} out of ${totalAtomsCount}`;

  return (
    <div className={style.interactionsStatus}>
      {title}
    </div>
  );
}
