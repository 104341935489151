import React from 'react';

import Button from 'common-ui-components/Button';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

import style from 'es-src/screens/HomeScreen/components/CleanPromptSuggestions/style.module.scss';

export const VERY_HIGH_CONFIDENCE_THRESHOLD = 95;
export const HIGH_CONFIDENCE_THRESHOLD = 70;
export const UNCHANGED_PROMPT_CONFIDENCE = -1;

export default function CleanPromptSuggestions() {
  const { data } = useEnterpriseSearchContext();

  if (!data || !data.cleanedPromptResult) return null;

  const { cleanedPrompt, confidenceScore, originalPrompt } = data.cleanedPromptResult;

  if (
    confidenceScore >= VERY_HIGH_CONFIDENCE_THRESHOLD
    || confidenceScore === UNCHANGED_PROMPT_CONFIDENCE
  ) return null;

  if (confidenceScore >= HIGH_CONFIDENCE_THRESHOLD) {
    return (
      <HighConfidenceSuggestion
        originalPrompt={originalPrompt}
        cleanedPrompt={cleanedPrompt}
      />
    );
  }

  return <LowConfidenceSuggestion cleanedPrompt={cleanedPrompt} />;
}

function HighConfidenceSuggestion({
  cleanedPrompt,
  originalPrompt,
}: {
  cleanedPrompt: string;
  originalPrompt: string;
}) {
  const { handleEnterpriseSearch } = useEnterpriseSearchContext();
  return (
    <div className={style.container}>
      <span className={style.bigText}>
        Showing results for
        {' '}
        <Button
          transparent
          className={style.bigText}
          onClick={() =>
            handleEnterpriseSearch(cleanedPrompt, 'cleanedQuestion', true)}
        >
          <span className={style.highlightedText}>{cleanedPrompt}</span>
        </Button>
      </span>
      <span className={style.smallText}>
        Search instead for
        {' '}
        <Button
          transparent
          className={style.smallText}
          onClick={() =>
            handleEnterpriseSearch(originalPrompt, 'originalQuestion', true)}
        >
          <span className={style.highlightedText}>{originalPrompt}</span>
        </Button>
      </span>
    </div>
  );
}

function LowConfidenceSuggestion({ cleanedPrompt }: { cleanedPrompt: string }) {
  const { handleEnterpriseSearch } = useEnterpriseSearchContext();
  return (
    <div className={style.container}>
      <span className={style.bigText}>
        Did you mean
        {' '}
        <Button
          transparent
          className={style.bigText}
          onClick={() =>
            handleEnterpriseSearch(cleanedPrompt, 'cleanedQuestion', true)}
        >
          <span className={style.highlightedText}>{cleanedPrompt}</span>
        </Button>
      </span>
    </div>
  );
}
