import { startOfDay, subMonths } from 'date-fns';

import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

export default function getInitialDatesRange(): DatesRange {
  const today = new Date();
  return {
    from: startOfDay(subMonths(today, 3)),
    to: startOfDay(today),
  };
}
