import React, { ReactNode, useCallback, useRef } from 'react';

import useEnrichedSourcesData from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import EnterpriseSearchContext, { EnterpriseSearchError } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useEnterpriseSearchFallback
  from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchFallbackHook';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { EnterpriseSearchResponse, QuestionSource } from 'global/api/controller/EnterpriseSearchController';
import { SearchResultsResponse } from 'global/api/controller/SearchController';
import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

type Props = {
  children: ReactNode;
};

export default function EnterpriseSearchContextProvider({ children }: Props) {
  const [originalQuestion, setOriginalQuestion] = useMountedState('');
  const [loading, setLoading] = useMountedState(false);
  const [error, setError] = useMountedState<EnterpriseSearchError | null>(null);
  const [data, setData] = useMountedState<EnterpriseSearchResponse | null>(null);
  const [
    searchResultsResponse, setSearchResultsResponse,
  ] = useMountedState<SearchResultsResponse | null>(null);
  const [isResultCardOpen, setEnterpriseResultCard] = useMountedState(data !== null);
  const { tenant } = useTenantContext();
  const abortControllerRef = useRef<AbortController | null>(null);

  const abortCurrentRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const performEnterpriseSearch = async (
    tenantId: number,
    searchQuery: string,
    questionSource: QuestionSource,
    skipPromptCleaning: boolean,
  ) => {
    const response = await Api.EnterpriseSearch.enterpriseSearch(
      tenantId,
      searchQuery,
      questionSource,
      abortControllerRef.current?.signal,
      skipPromptCleaning,
    );
    if (response?.data) {
      setData(response.data);
      Analytics.trackEvent(
        AnalyticsEvent.ENTERPRISE_SEARCH_USED,
        { query: searchQuery, sessionId: response.data.sessionId, location: 'Feed' },
      );
    } else {
      throw new Error('Error in Enterprise Search response');
    }
  };

  const performSearchQuery = async (
    tenantId: number,
    searchQuery: string,
  ) => {
    setSearchResultsResponse(null);
    const searchResponse = await Api.Search.searchQuery(
      tenantId,
      searchQuery,
      { signal: abortControllerRef.current?.signal },
    );
    if (searchResponse?.data) {
      setSearchResultsResponse(searchResponse.data);
      Analytics.trackEvent(
        AnalyticsEvent.TERM_SEARCHED,
        {
          term: searchQuery,
          gotResults: !!searchResponse.data && searchResponse.data.topics.totalResultsCount > 0,
        },
      );
    } else {
      throw new Error('Error in Search response');
    }
  };

  const handleEnterpriseSearch = useCallback(async (
    searchQuery: string,
    questionSource: QuestionSource,
    skipPromptCleaning: boolean = false,
  ) => {
    abortCurrentRequest();
    abortControllerRef.current = new AbortController();

    try {
      setLoading(true);
      setError(null);
      setData(null);
      setSearchResultsResponse(null);
      setEnterpriseResultCard(false);
      setOriginalQuestion(searchQuery);
      await Promise.all([
        performSearchQuery(tenant.id, searchQuery),
        performEnterpriseSearch(tenant.id, searchQuery, questionSource, skipPromptCleaning),
      ]);
    } catch (err) {
      if (abortControllerRef.current.signal.aborted) {
        setError('abort');
      } else {
        setError('network');
      }
    } finally {
      setLoading(false);
      setEnterpriseResultCard(true);
    }
  }, [tenant.id]);

  const handleAbortSearch = (searchQuery: string) => {
    DebuggerConsole.log('User aborted search result', { searchQuery });
    abortCurrentRequest();
    setEnterpriseResultCard(true);
    setError('abort');
    setLoading(false);
    setOriginalQuestion(searchQuery);
  };

  const dataWithFallback = useEnterpriseSearchFallback(data, searchResultsResponse, loading);
  const enrichedData = useEnrichedSourcesData(dataWithFallback);

  return (
    <EnterpriseSearchContext.Provider
      value={{
        originalQuestion,
        loading,
        hasError: error,
        data: enrichedData,
        searchResultsResponse: {
          value: searchResultsResponse,
          set: setSearchResultsResponse,
        },
        isResultCardOpen,
        handleEnterpriseSearch,
        handleAbortSearch,
        closeResultCard: () => setEnterpriseResultCard(false),
      }}
    >
      {children}
    </EnterpriseSearchContext.Provider>
  );
}
