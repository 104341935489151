// SearchResultCard.js

import classNames from 'classnames';
import React, { FC, ReactNode, SVGProps } from 'react';

import CardContainer from 'common-ui-components/CardContainer';
import { Heading2 } from 'common-ui-components/Typograhpy';
import { EnterpriseSearchError } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

import style from 'es-src/screens/HomeScreen/components/SearchResultCard/style.module.scss';

interface Props {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  children: ReactNode;
  hasError?: EnterpriseSearchError | null;
  className?: string;
}

export default function SearchResultCard({
  className,
  icon: Icon,
  title,
  children,
  hasError,
}: Props) {
  return (
    <CardContainer className={classNames(
      style.searchResultCard,
      hasError && style.error,
      className,
    )}
    >
      <div className={style.searchResultContent}>
        <div className={style.iconWrapper}>
          <Icon />
        </div>
        <div className={style.resultDetails}>
          <Heading2 className={style.question}>{title}</Heading2>
          {children}
        </div>
      </div>
    </CardContainer>
  );
}
