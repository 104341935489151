import React, { useEffect, useState } from 'react';

import CardContainer from 'common-ui-components/CardContainer';
import Spinner from 'common-ui-components/Spinner';
import SkeletonElement from 'es-src/components/ui/Skeleton/SkeletonElement';
import SkeletonShimmer from 'es-src/components/ui/Skeleton/SkeletonShimmer';
import AnswerSessionContent
  from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/AnswerSessionContent';
import SearchSessionContent
  from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/SearchSessionContent';
import SearchResultCard from 'es-src/screens/HomeScreen/components/SearchResultCard';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { TRANSITION_ANIMATION_LENGTH } from 'global/constants';
import ErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage';
import NetworkErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage/NetworkErrorMessage';
import TextLoader from 'screens/platform/cross-platform-components/TextLoader';

import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/style.module.scss';

export default function EnterpriseSearchResult() {
  const {
    loading, data, originalQuestion, hasError,
  } = useEnterpriseSearchContext();
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (loading) {
      timeoutId = setTimeout(() => {
        setShowSkeleton(true);
      }, TRANSITION_ANIMATION_LENGTH * 2);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading]);

  if (loading && showSkeleton) return <EnterpriseSearchResultSkeleton />;

  if (data === null && !hasError) return null;

  const shouldRenderContent = !hasError && data !== null;
  const integrateRelevantPeopleInAnswer = data !== null
    && !data.isSuccessfulAnswer
    && data.relevantPeople.length > 0;

  return (
    <SearchResultCard icon={SparklesIcon} title={originalQuestion} hasError={hasError}>
      {shouldRenderContent && data !== null && (
        data.isSearchQuery
          ? <SearchSessionContent suggestedQuestions={data.suggestedQuestions} />
          : (
            <AnswerSessionContent
              data={data}
              integrateRelevantPeopleInAnswer={integrateRelevantPeopleInAnswer}
            />
          )
      )}
      {hasError === 'network' && <NetworkErrorMessage />}
      {hasError === 'abort' && <ErrorMessage heading="Whoops! You cancelled. Maybe try a different question?" />}
    </SearchResultCard>
  );
}

function EnterpriseSearchResultSkeleton() {
  return (
    <CardContainer className={style.skeleton}>
      <div className={style.searchResultContent} data-testid="es-result-skeleton">
        <div className={style.spinnerContainer} data-testid="es-result-spinner">
          <Spinner />
        </div>
        <div className={style.resultDetails}>
          <div className={style.skeletonColumContainer}>
            <TextLoader enterpriseSearch />
            <SkeletonElement type="paragraph" />
          </div>
          <div className={style.skeletonColumContainer}>
            <SkeletonElement type="subtitle" />
            <div className={style.skeletonRowContainer}>
              <SkeletonElement type="badge" />
              <SkeletonElement type="badge" />
              <SkeletonElement type="badge" />
            </div>
          </div>
          <SkeletonElement type="subtitle" />
        </div>
        <SkeletonShimmer />
      </div>
    </CardContainer>
  );
}
