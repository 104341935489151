import { subMonths } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import { Heading3 } from 'common-ui-components/Typograhpy';
import OrganizationBadge from 'es-src/components/OrganizationBadge';
import AtomSearchResult from 'es-src/screens/HomeScreen/components/RegularSearchResults/AtomSearchResult';
import SearchResultCard from 'es-src/screens/HomeScreen/components/SearchResultCard';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { TagWithCategories } from 'global/api/controller/SearchController';
import Api from 'global/api/platformApi';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import { MinimalPerson } from 'model/Person';
import BadgesList from 'screens/platform/cross-platform-components/BadgesList';
import { useCustomAtomsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { toApp, toDirectionality } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage';
import PersonBadge from 'screens/platform/cross-platform-components/PersonBadge';
import Filters from 'screens/platform/directory/components/ProfileTabs/components/Filters';
import InteractionsCount from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount';

import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/icon/magnifying-glass-icon.svg';

import style from 'es-src/screens/HomeScreen/components/RegularSearchResults/style.module.scss';

interface SearchResultsSectionProps<T> {
  title: string;
  data: T[];
  renderBadge: (item: T) => JSX.Element;
}

function SearchResultsSection<T>({ title, data, renderBadge }: SearchResultsSectionProps<T>) {
  if (data.length === 0) return null;

  return (
    <div className={style.sectionContainer}>
      <Heading3>{title}</Heading3>
      <BadgesList<T> data={data}>
        {renderBadge}
      </BadgesList>
    </div>
  );
}

const RegularSearchResults = () => {
  const { searchResultsResponse, originalQuestion } = useEnterpriseSearchContext();
  const { getTagColors } = useMetadataContext();
  const { tenant } = useTenantContext();
  const { dispatchCustomAtomsFilters, customAtomsFilters } = useCustomAtomsFilters();
  const [hasUsedFilters, setHasUsedFilters] = useState(false);
  const [allAtomsCount, setAllAtomsCount] = useState(0);

  useEffect(() => {
    // TODO: This is only until we implement pagination.
    // We only update all atoms count before we've used any filters.
    // Because that's the time when all atoms are present.
    if (!hasUsedFilters) {
      setAllAtomsCount(searchResultsResponse.value?.atoms.results.length || 0);
    }
  }, [searchResultsResponse.value]);

  useEffect(() => {
    const filterAtoms = async () => {
      if (!searchResultsResponse.value) return;

      const response = await Api.Search.searchQuery(tenant.id, originalQuestion, {
        datesRange: customAtomsFilters.datesRange,
        topics: customAtomsFilters.topics,
        appIds: customAtomsFilters.apps?.map(toApp),
        participantsPersonIds: customAtomsFilters.people,
        organizationsIds: customAtomsFilters.organizations,
        directionality: toDirectionality(customAtomsFilters.directionality),
      });

      if (!response?.data) {
        return;
      }

      setHasUsedFilters(true);
      searchResultsResponse.set(response?.data);
    };

    filterAtoms();
  }, [customAtomsFilters]);

  useEffect(() => {
    setHasUsedFilters(false);
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: {
        datesRange: { from: subMonths(new Date(), 3), to: new Date() },
      },
    });
  }, [originalQuestion]);

  const hasSearchResults = useMemo(() => {
    const { value: responseValue } = searchResultsResponse;
    if (responseValue === null) return false;
    const {
      atoms, organizations, persons, topics,
    } = responseValue;
    return (
      atoms.results?.length > 0
      || organizations?.results?.length > 0
      || persons?.results?.length > 0
      || topics?.results?.length > 0
    );
  }, [searchResultsResponse]);

  if (!hasSearchResults && !hasUsedFilters) return null;

  const {
    atoms, organizations, persons, topics,
  } = searchResultsResponse?.value || {};

  return (
    <SearchResultCard
      icon={MagnifyingGlassIcon}
      title={`Search Results for "${originalQuestion}"`}
    >
      <div className={style.resultsSections}>
        {persons?.results && (
        <SearchResultsSection<MinimalPerson>
          title="People"
          data={persons.results}
          renderBadge={(person) => (
            <PersonBadge
              personId={person._id}
              hasDepartment
              hasBorder
              size={Size.MEDIUM}
            />
          )}
        />
        )}

        {organizations?.results && (
        <SearchResultsSection<Organization>
          title="Organizations"
          data={organizations.results}
          renderBadge={(organization) =>
          // TODO: deprecate this component in the following PR
            <OrganizationBadge organizationId={organization.id} />}
        />
        )}

        {topics?.results && (
        <SearchResultsSection<TagWithCategories>
          title="Topics"
          data={topics.results}
          renderBadge={({ tag }) =>
            <TagBadge tag={tag} colors={getTagColors(tag)} clickable openNewTab />}
        />
        )}

        <div className={style.interactions}>
          <div className={style.interactionsSummary}>
            <Heading3 className={style.heading}>Interactions</Heading3>
            <InteractionsCount
              totalAtomsCount={allAtomsCount}
              filteredAtomsCount={atoms?.results.length || 0}
            />
          </div>
          <div className={style.interactionsFilters}>
            <Filters />
          </div>
          {(atoms && atoms?.results.length > 0) ? (
            <div className={style.sectionContainer}>
              <div>
                {atoms.results.map((atom) => <AtomSearchResult key={atom.id} atom={atom} />)}
              </div>
            </div>
          )
            : (
              <ErrorMessage
                heading="We couldn't find any results that match your filtering criteria."
                subtitle="Please change your filters and try again."
              />
            )}
        </div>
      </div>
    </SearchResultCard>
  );
};

export default RegularSearchResults;
