import { useEffect, useMemo } from 'react';

import { EnterpriseSearchResponse } from 'global/api/controller/EnterpriseSearchController';
import { SearchResultsResponse } from 'global/api/controller/SearchController';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import { getOrganizationName } from 'utils/OrganizationUtils';

export default function useEnterpriseSearchFallback(
  data: EnterpriseSearchResponse | null,
  searchResultsResponse: SearchResultsResponse | null,
  loading: boolean,
): EnterpriseSearchResponse | null {
  const [fallbackData, setFallbackData] = useMountedState<EnterpriseSearchResponse | null>(null);
  const { tenant } = useTenantContext();

  const regularSearchResults = useMemo(() => {
    if (searchResultsResponse === null) {
      return {
        hasResults: false,
        hasOneResult: false,
        hasMultipleResults: false,
      };
    }

    const {
      atoms, organizations, persons, topics,
    } = searchResultsResponse;

    const totalCountSum = [atoms, organizations, persons, topics]
      .reduce((sum, result) => sum + (result ? result.totalResultsCount : 0), 0);

    return {
      hasResults: totalCountSum !== 0,
      hasSingleResult: totalCountSum === 1,
      hasMultipleResults: totalCountSum > 1,
    };
  }, [searchResultsResponse]);

  useEffect(() => {
    if (searchResultsResponse === null || data === null || loading) {
      setFallbackData(null);
      return;
    }

    const { relevantPeople, isSuccessfulAnswer } = data;

    if (isSuccessfulAnswer || relevantPeople.length > 0) {
      setFallbackData(data);
      return;
    }

    if (relevantPeople.length === 0 && !regularSearchResults.hasResults) {
      setFallbackData({
        ...data,
        answer: "It seems like I don't have a good answer for this question. Please try another one",
      });
      return;
    }

    if (regularSearchResults.hasMultipleResults) {
      setFallbackData({
        ...data,
        answer: "It seems like I don't have a good answer for this question, but don't worry -- you can have a look at the search results below",
      });
      return;
    }

    if (regularSearchResults.hasSingleResult) {
      const singleResultData = getSingleSearchResultAsSource(
        searchResultsResponse,
        tenant.name,
      ) ?? {};

      setFallbackData({ ...data, ...singleResultData });
    }
  }, [searchResultsResponse, regularSearchResults, data, loading]);

  return fallbackData;
}

function getSingleSearchResultAsSource(
  searchResultsResponse: SearchResultsResponse,
  tenantName: string,
): Pick<EnterpriseSearchResponse, 'sources' | 'answer'> | null {
  const {
    atoms, organizations, persons, topics,
  } = searchResultsResponse;

  if (organizations.totalResultsCount > 0) {
    const organizationName = getOrganizationName(organizations.results[0]);
    return {
      sources: [{ type: 'organization', data: { id: organizations.results[0].id } }],
      answer: `${organizationName} is an organization that ${tenantName} is working with`,
    };
  }

  if (persons.totalResultsCount > 0) {
    return {
      sources: [{ type: 'person', data: { _id: persons.results[0]._id } }],
      answer: `${persons.results[0].name} is a person that you work with`,
    };
  }

  if (topics.totalResultsCount > 0) {
    return {
      sources: [],
      answer: `${topics.results[0].tag} is a topic that ${tenantName} is working on`,
    };
  }

  if (atoms.totalResultsCount > 0) {
    return {
      sources: [{ type: 'atom', data: atoms.results[0] }],
      answer: 'We found this relevant interaction',
    };
  }

  DebuggerConsole.error('Expected a single search result but got non');
  return null;
}
