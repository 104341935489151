import React from 'react';

import { Heading3 } from 'common-ui-components/Typograhpy';
import Size from 'global/lists/Size';
import BadgesList from 'screens/platform/cross-platform-components/BadgesList';
import PersonBadge from 'screens/platform/cross-platform-components/PersonBadge';

import style
  from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/RelevantPeopleSection/style.module.scss';

const MAX_RELEVANT_PEOPLE = 3;

interface Props {
  relevantPeople: string[];
  includeTitle?: boolean;
}

export default function RelevantPeopleSection({ relevantPeople, includeTitle = true }: Props) {
  if (relevantPeople.length === 0) return null;

  const displayedRelevantPeople = relevantPeople.slice(0, MAX_RELEVANT_PEOPLE);

  return (
    <div className={style.relevantPeople} data-testid="relevant-people-section">
      {includeTitle && <Heading3>Relevant People</Heading3>}
      <BadgesList<string> data={displayedRelevantPeople}>
        {(personId) => (
          <PersonBadge
            personId={personId}
            hasDepartment
            hasBorder
            size={Size.MEDIUM}
          />
        )}
      </BadgesList>
    </div>
  );
}
