import React from 'react';

import { Heading3 } from 'common-ui-components/Typograhpy';
import SuggestedQuestion from 'es-src/screens/HomeScreen/components/SuggestedQuestions/SuggestedQuestion';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearchResult/components/SearchSessionContent/style.module.scss';

interface Props {
  suggestedQuestions: string[];
}

export default function SearchSessionContent({ suggestedQuestions }: Props) {
  return (
    <div className={style.container}>
      <Heading3>RELEVANT QUESTIONS</Heading3>
      <div className={style.questions}>
        {suggestedQuestions.map((question) => (
          <SuggestedQuestion key={question} question={question} questionSource="relevantSearchQuestion" color="blue" />
        ))}
      </div>
    </div>
  );
}
