import colors from 'global/colors';
import App, { ParentApp } from 'global/lists/apps';

type Color = string;

const parentAppsColors: Record<Exclude<ParentApp, App.GOOGLE>, Color> = {
  [App.ATLASSIAN]: '#1F7CF5',
  [App.PHABRICATOR]: '#FC573B',
  [App.M365]: '#00adef',
};
const appsColors: Record<App, Color> = {
  ...parentAppsColors,
  [App.AKOODA]: colors.DARK_GRAY,
  [App.SLACK]: '#E3B555',
  [App.GOOGLE]: colors.DARK_GRAY,
  [App.GOOGLE_DRIVE]: '#30A24F',
  [App.GOOGLE_CALENDAR]: '#4081ED',
  [App.GOOGLE_MEET]: '#FBB900',
  [App.DROPBOX]: '#D3E2F6',
  [App.ATLASSIAN_JIRA]: parentAppsColors[App.ATLASSIAN],
  [App.ATLASSIAN_CONFLUENCE]: parentAppsColors[App.ATLASSIAN],
  [App.GITHUB]: '#D5D5D5',
  [App.MONDAY]: '#06D446',
  [App.FIGMA]: '#14C4BE',
  [App.NOTION]: '#C1C1C1',
  [App.GOOGLE_GMAIL]: '#E13E2F',
  [App.INTERCOM]: '#08233E',
  [App.PHABRICATOR_BRANCHES]: parentAppsColors[App.ATLASSIAN],
  [App.PHABRICATOR_TICKETS]: parentAppsColors[App.ATLASSIAN],
  [App.BITBUCKET]: parentAppsColors[App.ATLASSIAN],
  [App.ZOOM]: '#39394D',
  [App.SALESFORCE]: '#00A1E0',
  [App.ZENDESK]: '#17494D',
  [App.BOX]: '#0061D5',
  [App.M365_SHAREPOINT]: '#008085',
  [App.M365_ONEDRIVE]: '#1490DF',
  [App.ASANA]: '#F56061',
  [App.LINEAR]: '#828FFF',
  [App.HUBSPOT]: '#FE5C35',
  [App.FRONT]: '#015C93',
  [App.AIRTABLE]: '#eae70e',
  [App.MIRO]: '#FFD02F',
};

export default appsColors;
