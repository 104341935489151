import { Margin } from '@nivo/core';
import { addDays, endOfDay } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import makeCursorPointer from 'screens/platform/contentScreens/AnalyticsScreen/utils/pointerCursorUtils';
import { useTooltipTitleByDate } from 'screens/platform/contentScreens/AnalyticsScreen/utils/TooltipTitle/TooltipTitleHooks';
import {
  VolumeTimelineChartDataType,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/VolumeTimelineWidget/dataFetching/VolumeTimelineChartDataFetcherHook';
import { DateRangeGranularity, GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import InteractionsCountValidator
  from 'screens/platform/cross-platform-components/Interactions/InteractionsCountValidator';
import TimeLineChart from 'screens/platform/cross-platform-components/TimeLineChart';
import LineChartTooltip from 'screens/platform/cross-platform-components/VolumeTimeLineChart/LineChartTooltip';

interface Props {
  chartData: VolumeTimelineChartDataType[];
  group: GroupType | null;
  granularity: DateRangeGranularity;
  margin?: Partial<Margin>;
  className?: string;
  disableClicking?: boolean;
}

function useInteractionsCountValidator(granularity: DateRangeGranularity) {
  const getTitle = useTooltipTitleByDate(granularity);

  return useCallback((date: Date) => {
    const title = getTitle(date);
    if (typeof title === 'string') {
      const interactionsCount = title.match(/[^(]+\((\d+) interactions\)$/)?.[1];
      InteractionsCountValidator.setExpectedInteractionsCount(Number(interactionsCount));
    }
  }, []);
}

export default function VolumeTimeLineChart({
  chartData,
  group,
  granularity,
  margin,
  className,
  disableClicking = false,
}: Props) {
  const { dispatchCustomAtomsFilters } = useCustomAtomsFilters();
  const validateInteractionsCountByDate = useInteractionsCountValidator(granularity);

  const maxVolume = useMemo(() => {
    const allDataSets = chartData.flatMap(({ data }) => data);
    return Math.max(...allDataSets.map((d) => d.y || 0));
  }, [chartData]);

  function setHighlightSpecificDate(from: Date | null) {
    if (from) {
      const to = endOfDay(
        granularity === DateRangeGranularity.DAY
          ? from
          : addDays(from, 6),
      );
      dispatchCustomAtomsFilters({
        type: 'SET_FILTERS',
        payload: {
          datesRange: { from, to: to || from },
        },
      });
    } else {
      dispatchCustomAtomsFilters({ type: 'DELETE_FILTERS', payload: { type: 'datesRange' } });
    }
  }

  return (
    <TimeLineChart
      data={chartData}
      granularity={granularity}
      maxValue={maxVolume}
      className={className}
      sliceTooltip={({ slice }) => (
        <LineChartTooltip
          slice={slice}
          maxVolume={maxVolume}
          group={group}
          granularity={granularity}
        />
      )}
      onClick={disableClicking ? undefined : (event) => {
        // @ts-ignore
        const date = event.points[0].data.x as Date;

        validateInteractionsCountByDate(date);
        setHighlightSpecificDate(date);
      }}
      onMouseEnter={disableClicking ? undefined : makeCursorPointer}
      margin={margin}
    />
  );
}
