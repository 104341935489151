import { startOfDay } from 'date-fns';

import App from 'global/lists/apps';
import EventCategory from 'global/lists/EventCategory';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

type RequiredEntity = {
  type: keyof Pick<CustomFilters, 'topics' | 'organizations' | 'people'>;
  values: string[];
};

export type CustomFilters = Partial<{
    datesRange: DatesRange;
    people: string[];
    peopleTuple: string[];
    clusters: Record<string, string[]>;
    departments: string[];
    // 'topics' will replace 'tag' and 'category' after the Analytics screen is refactored.
    // We can then deprecate 'tag' and 'category'.
    // More details: https://akoodaco.atlassian.net/browse/AKD-5613
    topics: string[];
    requiredTag: string;
    requiredOrganization: string;
    tag: string;
    category: string;
    searchQuery: string;
    organizations: string[];
    eventsCategories: EventCategory[];
    directionality: Directionality[];
    apps: (App | string)[];
    includeUntagged: boolean;
    requiredEntity: RequiredEntity;
}>

export const EMPTY_CUSTOM_FILTERS = {};

export type CustomFiltersReducerAction<T extends CustomFilters> =
  | { type: 'RESET_ALL' }
  | { type: 'ADD_FILTERS'; payload: T }
  | { type: 'SET_FILTERS'; payload: T }
  | { type: 'DELETE_FILTERS'; payload: { type: keyof T } };

export function getCustomFiltersReducerFunction<T extends CustomFilters>() {
  return (
    state: Partial<T>,
    action: CustomFiltersReducerAction<T>,
  ): Partial<T> => {
    switch (action.type) {
      case 'RESET_ALL':
        return EMPTY_CUSTOM_FILTERS;

      case 'SET_FILTERS':
      case 'ADD_FILTERS': {
        const filtersToAdd = action.payload;

        const nextState: Partial<T> = action.type === 'SET_FILTERS' ? {} : { ...state };
        Object.keys(filtersToAdd).forEach(((key: keyof CustomFilters) => {
          if (key === 'datesRange') {
            const { to, from } = filtersToAdd[key] as DatesRange;
            nextState.datesRange = {
              from: startOfDay(from),
              to: startOfDay(to),
            };
          } else if (key === 'peopleTuple') {
            nextState.peopleTuple = filtersToAdd[key] as [string, string];
            delete nextState.clusters;
          } else if (key === 'clusters') {
            nextState.clusters = filtersToAdd[key] as Record<string, string[]>;
            delete nextState.peopleTuple;
          } else if (key === 'organizations') {
            nextState.organizations = filtersToAdd[key] as [string, string];
          } else if (key === 'departments') {
            nextState.departments = filtersToAdd[key] as [string, string];
          } else if (key === 'eventsCategories') {
            nextState.eventsCategories = filtersToAdd[key] as EventCategory[];
          } else if (key === 'tag') {
            nextState.tag = filtersToAdd[key] as string;
          } else if (key === 'category') {
            nextState.category = filtersToAdd[key] as string;
          } else if (key === 'searchQuery') {
            nextState.searchQuery = filtersToAdd[key];
          } else if (key === 'directionality') {
            nextState.directionality = filtersToAdd[key] as Directionality[];
          } else if (key === 'apps') {
            nextState.apps = filtersToAdd[key] as App[] | string[];
          } else if (key === 'people') {
            nextState.people = filtersToAdd[key] as string[];
          } else if (key === 'topics') {
            nextState.topics = filtersToAdd[key] as string[];
          } else if (key === 'requiredEntity') {
            nextState.requiredEntity = filtersToAdd[key] as RequiredEntity;
          } else if (key === 'includeUntagged') {
            nextState.includeUntagged = filtersToAdd[key] as boolean;
          }
        }) as any);

        return nextState;
      }

      case 'DELETE_FILTERS': {
        const { type } = action.payload;

        const nextCustomAtomsFilters = { ...state };
        delete nextCustomAtomsFilters[type];
        if (state.requiredEntity) {
          nextCustomAtomsFilters[state.requiredEntity.type] = state.requiredEntity.values;
        }

        return nextCustomAtomsFilters;
      }

      default:
        return state;
    }
  };
}

export function toApp(app: string | App) {
  return typeof app === 'string' ? parseInt(app, 10) : app;
}

export function toDirectionality(directionality?: Directionality[]) {
  const ALL_DIRECTIONS = undefined;

  if (!directionality) {
    return ALL_DIRECTIONS;
  }

  if (directionality.length > 1) {
    return ALL_DIRECTIONS;
  }

  return directionality[0];
}
