import React, { useCallback, useState } from 'react';

import DatePickerDropdown from 'common-ui-components/DatePickerDropdown';
import Spinner from 'common-ui-components/Spinner';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import getInitialDatesRange from 'screens/platform/directory/components/ProfileTabs/components/Filters/DateRangeUtils';
import FilterButton from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton';
import { getDatesFilterLabel } from 'utils/DateUtils';
import ObjectUtils from 'utils/ObjectUtils';

interface Props {
  disabled?: boolean;
}

export default function DatesRangeFilter({ disabled }: Props) {
  const {
    dispatchCustomAtomsFilters,
    customAtomsFilters: { datesRange },
  } = useCustomAtomsFilters();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const initialDatesRange = getInitialDatesRange();

  const setDatesRange = useCallback((datesRangeToUpdate: DatesRange) => {
    dispatchCustomAtomsFilters({
      type: 'ADD_FILTERS',
      payload: {
        datesRange: datesRangeToUpdate,
      },
    });
  }, [dispatchCustomAtomsFilters]);

  if (!datesRange) return <Spinner />;

  const isDefaultRange = ObjectUtils.equals(datesRange, initialDatesRange);

  return (
    <DatePickerDropdown
      datesRange={datesRange}
      setDatesRange={setDatesRange}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      toggleButtonElement={(
        <FilterButton
          closeFilterDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
          hasSelectedValues
          isDropdownOpen={isDropdownOpen}
          disabled={disabled}
        >
          {isDefaultRange ? 'Time' : getDatesFilterLabel(datesRange)}
        </FilterButton>
      )}
      placement="bottom-start"
    />
  );
}
